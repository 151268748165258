body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
  display: inline-block;
  margin-left: 2px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: ''!important;
  margin-right: 0px !important;
}