/* content page */
.sidebar {
  width: 22% !important;
  padding-bottom: 3.5rem;
}
.content {
  padding-left: 0;
}
.margineelement {
  padding-bottom: 6rem;
  min-height: 463px;
}

/* .divwh{
    height:100%;
    width:100%;
    display: flex;
    align-items: center;
  } */

.example {
  height: 80vh;
  /* width:200vh; */
  display: flex;
  align-items: center;
  /* margin: 20px 0; */
  /* margin-bottom: 20px; */
  /* padding: 30px 50px; */
  justify-content: center;
  /* border-radius: 4px; */
}
.main-content {
  min-height: calc(100vh - 126px);
  padding-top: 30px;
}

@media (min-width: 1400px) {
  .margineelement {
    min-height: 580px;
  }
}

@media screen and (min-width: 1800px) and (max-width: 1899px) {
  .main-content {

    max-width: 1860px;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1799px) {
  .main-content {

    max-width: 1760px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1699px) {
  .main-content {

    max-width: 97vw;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  .main-content {

    max-width: 97vw;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1499px) {
  .main-content {
    /* max-width: 1650px; */
    max-width: 97vw;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1399px) {
  .main-content {
    max-width: 97vw;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .main-content {
    max-width: 1250px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .main-content {
    max-width: 1160px;
  }
}

@media screen and (min-width: 800px) and (max-width: 999px) {
  .main-content {
    max-width: 848px;
  }
}