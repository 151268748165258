@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url(../font/SourceSansPro-Regular.ttf);
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: url(../font/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';
  src: url(../font/SourceSansPro-SemiBold.ttf);
}

* {
  font-family: 'SourceSansPro-Regular';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'SourceSansPro-Regular' !important;
  width: auto !important;
  height: auto !important;
}

a {
  color: #224b6a !important;
  font-family: 'SourceSansPro-SemiBold';

}

body iframe {
  display: none !important;
}

table.table span.info {
  color: #777;
}

.ant-form-item {
  margin-bottom: 5px !important;
}

.info {
  color: #777;
}

.badge {
  display: inline-block !important;
}

table.table th {
  background-color: #f3f4f7 !important;
  padding: 10px 13px !important;
  color: #222 !important;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 400 !important;
  font-family: 'SourceSansPro-SemiBold';
  border: 1px solid #dee2e6;
  white-space: nowrap;
}

table.table td {
  border-color: #dee2e6;
  border-width: 1px;
  font-family: 'SourceSansPro-Regular';
  padding: 8px 12px !important;
  vertical-align: middle;
  font-size: 13px;
}

.sidebar-ul-version {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 565px;
}

.logo-wrapper .img-fluid {
  margin: 0px 0;
  padding: 8px 0;
  height: 100%;
}

.img-fluid {
  max-width: 100%;
  height: 50px;
  width: 100%;
  padding: 5px;
  object-fit: contain;
}

.list-group-item {
  padding: 15px 15px;
}

.top-header {
  position: fixed;
  top: 0px;
  z-index: 100;
  left: 130px;
  right: 0;
  /* background: #164162f2; */
  background-image: url(../images/headerimg.png), linear-gradient(116deg, #133d5e 59%, #2f8f9f);
  height: 55px;
  background-repeat: no-repeat;
  background-size: cover;
  border-left: 1px solid white;
  box-shadow: 0px 0px 7px 0px white;
}

.tableFilterSection {
  background-color: #f3f4f7 !important;
  margin: 8px 8px -8px 8px;
  padding: 10px;
  border-radius: 13px;
}

.list-group-item-1 a li {
  transition: 0.3s;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 12px 15px;
  background-color: transparent;
  color: #ffffff;
  text-decoration: none;
  border: none;
  word-break: break-word;
  font-size: 14px;
}

.list-group-item-1 a li i {
  margin-right: 9px;
}

.list-group-item-1 a li.active {
  background-color: #ffffff54;
  color: #ffffff !important;
}

.list-group-item-1 a li:hover {
  background-color: #ffffff10;
  padding-left: 20px;
}

.profile img {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

.profile .profile-set {
  border-radius: 50%;
}

.name-btn {
  color: #fff;
  background: transparent !important;
  border: none !important;
}

.log-btn {
  margin-left: 10px;
}

.ml-10 {
  margin-left: 10px;
}

a.ant-typography,
.ant-typography a .name-profile {
  color: white;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}

.demo {
  margin-top: 55px;
}

.sidebar-fixed {
  width: 130px;
  /* background: #14466c; */
  background: linear-gradient(359deg, rgb(42 127 146) 0, rgb(19 61 94) 60%);
  height: 100vh;
}

.mt-60px {
  top: 0;
  left: 0;
}

.page-menu-nav li.nav-item a {
  color: #6c757d !important;
  text-transform: uppercase;
  font-family: 'SourceSansPro-SemiBold';
}

.page-menu-nav {
  display: flex;
  columns: auto;
  flex-direction: row;
  background-color: #ffffff !important;
  border-bottom: 1.1px solid #dee2e6 !important;
  position: relative;
  box-shadow: 3px 0 6px 0 #d8dde6;
}

.task-details {
  margin-top: 35px !important;
}

.leave-rows {
  margin: 5px 0;
}

.page-menu-nav li.nav-item a:after {
  content: "";
  position: absolute;
  bottom: -1px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid;
  left: 2%;
  opacity: 0;
  transition: 0.3s;
  color: #2986ce;
}

.page-menu-nav li.nav-item.active a:after {
  opacity: 1;
  left: 2%;
}

.page-menu-nav li.nav-item.active a.nav-link {
  color: #222 !important;
}

.page-menu-nav li.nav-item:hover a.nav-link {
  color: #1b1b1b !important;
}

#content {
  margin-left: 130px !important;
}

.project-body {
  margin-left: 0px !important;
  margin-right: 2px !important;
}

.mt-51 {
  margin-top: 51px !important;
}

.leave-btn {
  float: right;
}

.location-master-table .mdb-datatable-filter input {
  margin-left: 0 !important;
}

.location-master-table table tr th:nth-child(1) {
  width: 10%;
}

.location-master-table table tr th:nth-child(4) {
  width: 10%;
}

.leave-set-btn2 {
  display: flex;
  justify-content: space-between;
}

.response-img-part {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  margin: 10px 0;
  box-shadow: inset 1px 1px 15px -3px #1b5470;
}

.response-img-part img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin: 20px 0;
}

.no-leaves {
  display: flex;
  align-items: center;
}

.add-emp-pre-date {
  max-width: 310px;
  margin-left: 175px;
  margin-bottom: -32px;
}

.add-emp-pre-date .ant-picker-range {
  height: 28px;
}

.ant-btn.header-leave-btn {
  padding: 0px 10px;
  margin-right: 14px;
  min-height: auto !important;
  height: auto !important;
  font-size: 12px;
  background: #fff !important;
  border-color: #fff !important;
  color: #000 !important;
}

.delete-btn {
  margin-left: 5px;
  margin-right: 10px;
}

.leave-1 {
  font-weight: 600;
}

.leave-2 {
  padding-left: 5px;
  width: 40%;
}

.leave-3 {
  margin-left: 0px;
  margin-right: 20px;
}

.leave-4 {
  padding-left: 10px;
  width: 100%;
}

.logo-vh-set {
  display: flex;
  height: 55px;
  align-items: center;
  border-bottom: 1px solid white;
  box-shadow: 0px -3px 7px 3px #fff;
}

.footer-color {
  background-color: rgba(0, 0, 0, 0.2);
}

.list-search {
  font-weight: 600;
}

.list-search-2 {
  padding-left: 10px;
  width: "100%";
}

.start-list {
  font-weight: 600;
}

.start-list-2 {
  padding-left: 10px;
  width: 60%;
}

.sort-name {
  font-weight: 600;
}

.sort-name-inner {
  width: 100%;
}

.sort-section {
  flex-direction: column;
  display: flex;
}

.open-menu {
  margin-left: auto;
  padding: 0;
}

.cardmargine-text {
  color: rgb(1, 63, 179);
  padding: 5px;
}

.float-project {
  float: right;
}

.float-project-2 {
  float: left;
}

.status-box {
  text-transform: none;
  font-size: 1rem;
  padding: 0.3rem 1rem 0.3rem 1rem;
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pr-5 {
  padding-right: 5px;
}

.description {
  font-weight: 2;
  overflow-y: scroll;
  max-height: 325px;
  min-height: 143px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.comment-box {
  width: 66%;
  float: left;
}

.employees {
  color: rgb(1, 63, 179);
  margin-left: 10px;
  margin-top: 10px;
}

.employee-present {
  width: 15%;
}

.emp-preset-table .row,
.page-container-table .row,
.tasklisttbl .row,
.tbl-attendance .row,
.scrool-set .row {
  overflow: auto;
  margin: 0;
}

.tasklisttbl .row table thead tr th:last-child,
.scrool-set .row table thead tr th:last-child {
  width: 15.5%;
}

.leave-tbl .row table thead tr th:last-child {
  width: 5%;
}

.tasklisttbl .row table tbody tr td button i {
  color: #fff !important;
}

.all-emp-list .row table thead tr th:last-child {
  width: 5%;
}

.emp-preset-table table tr th,
.emp-preset-table table tr td {
  white-space: nowrap;
}

.ck-rounded-corners .ck-editor__main .ck-content {
  min-height: 150px;
  max-height: 150px;
  overflow: auto;
}

.ant-modal-header {
  top: 0;
  z-index: 9999;
  height: 50px;
  min-height: 50px;
  background-color: #336276 !important;
  /* margin-top: 20px !important; */
  border-radius: none !important;
  border: none !important;
}

.ant-modal-title {
  color: white !important;
  font-size: 20px !important;
  line-height: 16px !important;
  font-family: 'SourceSansPro-SemiBold';
}

.modal-btn {
  margin-right: 6px !important;
}

.modal-btn2 {
  margin-left: 6px !important;
}

.ant-modal-close-x {
  color: white !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.mb-0 .ant-row .ant-form-item {
  margin-bottom: 0px !important;
}

.ant-modal-header {
  border-radius: 0px !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff !important;
  margin: 0 5px;
  border-radius: 7px;
  padding: 5px 9px !important;
  transition: 0.5s;
  font-size: 15px;
  font-family: 'SourceSansPro-Regular' !important;
}

.card-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

.footer-set {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 97px);
  margin-left: 97px;
}

.mt-25 {
  margin-top: 25px;
}

.tablHeader,
.left-head-title {
  font-size: 26px !important;
  margin: 0;
  font-family: 'SourceSansPro-Bold';
  margin: 0px;
}

.project-title-div {
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
}

.page-menu-nav2 {
  display: flex;
  align-items: center;
}

.project-main-details .project-title-div .col:first-child h3 {
  margin: 0;
}

.new-tablHeader {
  padding: 5px 15px 5px 15px;
  background: white;
}

.page-menu-nav-pro {
  justify-content: space-between;
  padding: 5px 10px;
}

.pro-add-task .add-task-btn {
  padding: 0;
  min-height: auto;
  box-shadow: none;
  border: none;
}

.pro-add-task {
  display: flex;
  align-items: center;
}

.tablHeader2 {
  color: #222 !important;
  font-size: 26px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.plus-btn {
  font-size: 14px;
}

.qa-btn {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.qa-btn-set {
  display: flex;
}

.mr-10 {
  margin-right: 10px;
}

.box-width {
  width: 100%;
}

.page-container .tasklist-input {
  margin: 5px 0;
}

.submit-btn {
  text-align: end;
  /* margin-right: 47px; */
}

.mr-15 {
  margin-right: 15px;
}

.description,
.pro-view-tab {
  padding: 0px;
  row-gap: 0px;
}

.description-box {
  margin-bottom: 10px;
  margin-left: 5px;
}

li.nav-item.nav-bar-user-name {
  list-style: none;
}

.nav-bar-user-name .user-name {
  color: white !important;
  font-size: 15px;
}

.right-emp-btn button,
.right-emp-btn button:hover,
.right-emp-btn button:active,
.right-emp-btn button:focus {
  border-color: #297e92 !important;
  background: #336276 !important;
  box-shadow: 2px 1px 6px 0px rgb(0 0 0 / 25%) !important;
}

.pms-same-btn,
.pms-same-btn:hover,
.pms-same-btn:active,
.pms-same-btn:focus,
.ant-input-group-addon button {
  border-color: #297e92 !important;
  background: #336276 !important;
  box-shadow: 2px 1px 6px 0px rgb(0 0 0 / 25%) !important;
  color: #ffffff !important;
}

.ant-input-group-addon button {
  line-height: 0;
}

.pms-same-btn-cancel,
.pms-same-btn-cancel:hover,
.pms-same-btn-cancel:active,
.pms-same-btn-cancel:focus {
  border-color: #297e92 !important;
  background: #ffffff !important;
  box-shadow: 2px 1px 6px 0px rgb(0 0 0 / 25%) !important;
  color: #297e92 !important;
}

.delete-modal .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-emp-btn span {
  color: #ffffff;
}

.page-container {
  padding: 15px;

}

.page-content {
  padding: 10px 15px !important;
  border-radius: 0 !important;
}

.page-content div h5 {
  font-size: 26px !important;
  margin: 0 0 15px 0;
  font-family: 'SourceSansPro-Bold';
}

.mdb-datatable .ml-1:nth-child(1) div,
.mdb-datatable .row .col-sm-12 {
  padding: 0;
  margin: 0 0 2px 0;
}

.mdb-datatable div:nth-child(2) .col-sm-12 {
  padding: 0;
}

.mdb-datatable .ml-1 {
  margin: 0;
}

.search-box {
  padding-left: 0px;
}

.mdb-datatable-filter input {
  margin-left: 0 !important;
}

.depatment-table .mdb-dataTable tbody td button,
.table-hover tbody tr td button,
.table tr td button {
  background-color: #297e92;
  border-color: transparent;
  color: #fff;
}

.depatment-table .mdb-dataTable tbody td button:hover,
.table-hover tbody tr td button:hover,
.table tr td button {
  background-color: #297e92;
  border-color: transparent;
  color: #fff;
}

.depatment-table .mdb-dataTable tbody td button .fa-edit {
  color: white !important;
}

.create-project {
  display: flex !important;
  margin: 5px 0 15px 0;
}

.btn-right {
  float: right;
}

.text-font-weight {
  font-weight: 500;
}

.text-font-weight-600 {
  font-weight: 600;
}

.pl-10 {
  padding-left: 10px;
}

.position-btn {
  text-align: end;
  margin-right: 3px;
}

.badge {
  border-radius: 20px;
}

.btn-align-right {
  text-align: right;
}

.mr-5 {
  margin-right: 5px;
}

ul.employee-set li:first-child a {
  padding-left: 0 !important;
}

.search-rangepicker {
  padding-left: 10px;
  width: 100%;
}

.mb-3 {
  margin-bottom: 0 rem !important;
}

.image {
  width: 10%;
  border-radius: 11px;
  margin-left: 50px;
}

.rolebg {
  border-radius: 7px;
  background-color: #ffa900;
}

.positionbg {
  border-radius: 7px;
  background-color: #b23cfd;
}

p {
  font-size: 13px;
}

.cardmargine {
  margin-bottom: 35px;
}

.cardmarginebotom {
  margin-bottom: 135px;
}

.cardbackground {
  background-color: rgb(250 250 250);

}

.tabfont {
  font-size: 11px;
  color: black;
}

.ant-form-item-label {
  overflow: visible;
  white-space: nowrap !important;
}

.ant-col-10 {
  margin-left: 5%;
}

.colemmargine {
  margin-left: 44px;
  width: 15rem;
}

.colemwidth {
  width: 15rem;
  margin-left: 2.4rem;
}

.card {
  margin-left: 0 !important
}

.ant-row {
  display: flex;
  justify-content: space-between;
}

.ant-col-10 {
  margin-left: 0% !important;
}

.colemmargine {
  margin-left: 0px !important;
  width: 16rem;
}

.colemwidth {
  width: 16rem !important;
  margin-left: 0rem !important;
}

.ant-picker {
  width: 100%;
}

.date-input .ant-form-item-label {
  padding: 0 !important;
}

.ant-modal-content {
  margin: 20px 0;
}

/*Ashvin*/

.leave-form .card-header {
  padding: 0 !important;
}

ul.nav.nav-tabs.nav-justified.mb-31 {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #d9d9d9;
}

/* darkmode css */

.darkmode #content .demo .tablHeader {
  color: #e4e6e9 !important;
}

.darkmode #content .demo table.table th {
  background-color: #1b2531 !important;
  padding: 10px 15px !important;
  color: #e4e6e9 !important;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400 !important;
  border-color: #384252;
}

.darkmode {
  background-color: #1b2531 !important;
}

.darkmode #content .demo .mdb-datatable table tr {
  border-bottom: 1px solid #384252;
  background: #273143;
  color: white !important;
}

.darkmode #content .demo .card {
  background-color: #1b2531 !important;
  color: #e4e6e9 !important;
  border-radius: 0px !important;
}

.darkmode #content .demo .mdb-datatable table tr:hover {
  border-bottom: 1px solid #384252;
  background-color: #333d4eb3 !important;
  color: white !important;
}

.darkmode #content .demo .mdb-datatable table tr:hover td span.text-font-weight {
  color: #fff;
}

.darkmode #content .demo .mdb-datatable table tr:hover td span {
  color: #a2a9b4;
}

.darkmode #content .demo .mdb-datatable table.table span.info {
  color: #a2a9b4;
}

.darkmode #content .demo .table-hover>tbody>tr:hover>* {
  color: #fff;
}

.darkmode #content .demo {
  height: 100vh;
}

.darkmode #content .demo .page-menu-nav {
  display: flex;
  columns: auto;
  flex-direction: row;
  background-color: #2f394c !important;
  border-bottom: 1.1px solid #3e608400 !important;
  position: relative;
  /* box-shadow: 3px 0 6px 0 #d8dde6; */
  box-shadow: 3px 0 6px 0 #404e63 !important;
}

.darkmode #content .demo .page-menu-nav li.nav-item.active a.nav-link {
  color: #e4e6e9 !important;
}

.darkmode #content .demo .page-menu-nav li.nav-item a {
  color: #6c757d !important;
  text-transform: uppercase;
  font-weight: 500;
}

.darkmode #content .demo table tr td {
  border-color: #384252;
  border-bottom-width: 1px;
}

.darkmode #content .demo .page-menu-nav li.nav-item a:hover {
  color: #e4e6e9 !important;
  text-transform: uppercase;
  font-weight: 500;
}

.darkmode #content .project-body {
  margin-left: 0px !important;
  margin-right: 2px !important;
  margin-top: 50px !important;
  margin-left: -24px !important;
}

.project-sidebar {
  padding: 0;
}

.project-sidebar {
  padding: 0;
}

.header {
  margin-top: 0px;
  padding-left: 75px;
}


.btn-tresh-link {
  border: none !important;
  background: transparent;
  color: red !important;
  box-shadow: none !important;
}

.btn-add-link {
  border: none;
  background: transparent;
  color: rgb(1, 63, 179);
}

.m-left {
  margin-left: 55px;
}

.ant-col.ant-col-6.m-left.ml {
  margin-left: 15px;
  margin-top: 30px;
}



.project-main-details {
  padding: 0px 15px;
}

.project-title-div .btn-right button:nth-child(2) {
  margin-left: 15px;
}

.darkmode #content .project-sidebar .tablHeader {
  font-weight: 400 !important;
  font-size: 25px !important;
  color: #e4e6e9 !important;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  z-index: 1000;
  height: 100%;
  background-color: #000 !important;
}

.darkmode #content .project-sidebar ul {
  background: #273143;
  color: #9e9e9e;
}

.darkmode #content .project-sidebar ul li a {
  color: #9e9e9e;
}

.darkmode #content .project-sidebar .ant-menu-submenu-selected {
  color: white;
}

.darkmode #content .project-sidebar .ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  color: white !important;
}

.darkmode #content .project-sidebar .ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #394253 !important;
}

.darkmode #content .project-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #394253 !important;
}

.darkmode #content .project-sidebar .ant-btn:hover,
.darkmode #content .project-sidebar .ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: transparent;
}

.darkmode #content .project-sidebar .ant-btn {
  line-height: 7px;
  margin-bottom: 0px !important;
}

.side-box-project {
  padding-left: 0px;
}

.darkmode #content .project-sidebar .project-body .cardbackground {
  background-color: #1b2531;
}

.darkmode #content .project-sidebar .project-body .cardbackground .card {
  background-color: #1b2531;
}

.darkmode #content .project-sidebar .project-body .cardbackground .card .TitleStyle {
  color: #e4e6e9 !important;
}

.darkmode #content .project-sidebar .project-body .cardbackground .card .bg-light-text-color p {
  color: #e4e6e9;
}

.darkmode #content .project-sidebar .project-body .cardbackground .card .bg-light-text-color h6 {
  color: #e4e6e9;
}

.darkmode #content .project-sidebar .project-body .cardbackground .card .blockquote .bg-light-box-color {
  background-color: #394253 !important;
}

.darkmode #content .project-sidebar .project-body .cardbackground .ant-input {
  background-color: #394253;
  border-color: #394253 !important;
}

.darkmode #content .project-sidebar .project-body .cardbackground .ant-input:hover {
  border-color: #394253 !important;
  border-right-width: 1px;
}

.darkmode #content .project-sidebar .project-body .media-comment .g-bg-secondary {
  background-color: #394253 !important;
}

.darkmode #content .project-sidebar .project-body .media-comment .g-mb-15 span {
  color: #e4e6e9 !important;
}

.darkmode #content .project-sidebar .project-body .media-comment .g-mb-15 h6 {
  color: #e4e6e9;
}

.darkmode #content .project-sidebar .project-body .media-comment .g-bg-secondary .note-primary {
  background-color: #1b2531 !important;
  border-color: #3942538f !important;
  color: white !important;
}

.darkmode #content .project-sidebar .project-body .media-comment .u-shadow-v18 {
  /* box-shadow: 0 5px 10px -6px rgb(0 0 0 / 15%); */
  box-shadow: 0 0 10px 2px #404e63;
}

.darkmode #content .demo .tasklisttbl input,
.darkmode #content .demo .tasklisttbl input:focus {
  background-color: #1b2531;
  border-color: #384252 !important;
  box-shadow: none;
}

.darkmode table tr td a {
  color: #2986ce !important;
}

.darkmode .demo .ant-input-search-with-button .ant-input-affix-wrapper {
  background: #273143;
  border: 1px solid #384252;
  box-shadow: none;
}

.darkmode .demo .ant-input-search-with-button .ant-input {
  background: #273143;
}

.darkmode .demo .ant-input-search-with-button .ant-btn-primary:active {
  color: #fff;
  border: 1px solid #384252;
  background: #273143 !important;
}

.darkmode .demo .ant-input-search-with-button .ant-btn-primary {
  color: #fff;
  border: 1px solid #384252;
  background: #273143;
}

.darkmode .demo .ant-picker-range {
  background: #273143;
  border: 1px solid #384252;
  box-shadow: none;
}

.darkmode .demo .page-container .btn-right .ant-btn-primary:hover,
.darkmode .demo .page-container .btn-right .ant-btn-primary:focus {
  color: #fff;
  border-color: #2986ce;
  background: #1b2531;
}

.darkmode .demo .page-container .btn-right .ant-btn-primary {
  background: #273143;
  border-color: #384252;
}

.darkmode .demo .ant-picker-range-separator svg path {
  fill: white;
}

.darkmode .demo .ant-picker-suffix svg path {
  fill: white;
}

.darkmode .demo .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #273143 !important;
  border: 1px solid #384252;
}

.darkmode .demo .ant-select-selector .ant-select-arrow svg path {
  fill: white;
}

.darkmode .demo .select-dropdown .ant-select-selector .ant-select-selection-item {
  color: #e4e6e9;
}

.darkmode .demo .ant-select-arrow svg path {
  fill: #e4e6e9;
}

.darkmode .demo .ant-select-single .ant-select-selection-item {
  color: #e4e6e9 !important;
}

.darkmode .demo .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.darkmode .demo .ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
  background-color: #273143 !important;
  border: 1px solid #384252 !important;
  box-shadow: none !important;
}

.darkmode .ant-select-dropdown {
  background-color: #273143;
}

.darkmode .ant-select-item {
  color: #e4e6e9;
}

.darkmode .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #394253;
}

.darkmode .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  background-color: #394253;
}

/* darkmode-modal */
.darkmode-modal .ant-modal-header {
  background-color: #1b2531 !important;
  color: #e4e6e9 !important;
  border-bottom: 1px solid #384252 !important;
}

.darkmode-modal .ant-modal-body {
  background: #273143;
}

.darkmode-modal .ant-form-item-label>label {
  color: #e4e6e9 !important;
}

.darkmode-modal .ant-checkbox-wrapper {
  color: #e4e6e9 !important;
}

.mr-55 {
  margin-right: 55px;
}

.darkmode-modal .dark-modal-description {
  color: #e4e6e9 !important;
}

.darkmode-modal .ant-modal-root .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #273143;
  border: 1px solid #384252;
}

.darkmode-modal .ant-modal-root .ant-input {
  /* color: rgba(0, 0, 0, 0.85); */
  color: #e4e6e9 !important;
  background-color: #273143;
  border: 1px solid #384252;
  box-shadow: none;
}

.darkmode-modal .ant-modal-root .ant-input:hover {
  border: 1px solid #384252 !important;
  border-right-width: 1px;
}

.darkmode-modal .ant-modal-root .ant-picker {
  color: rgba(0, 0, 0, 0.85);
  background-color: #273143;
  border: 1px solid #384252;
}

.darkmode-modal .ant-modal-root .ant-btn-default {
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  color: #e4e6e9 !important;
  border-color: #384252;
  background: #273143;
}

.darkmode-modal .ant-modal-root .ant-btn-default:hover,
.darkmode-modal .ant-modal-root .ant-btn-default:focus,
.darkmode-modal .ant-modal-root .ant-btn-default:active {
  text-decoration: none;
  background: #273143;
  color: #e4e6e9 !important;
}

.uploadfile-btn {
  width: 340px !important;
  pointer-events: none !important;
}

.darkmode-modal .ant-modal-root .uploadfile-btn {
  width: 340px !important;
  pointer-events: none !important;
}

.darkmode-modal .ant-modal-root .ant-radio-wrapper {
  color: #e4e6e9;
}

.darkmode-modal .ant-modal-root .ant-form-item .ant-select-arrow svg path {
  fill: white;
}

.darkmode-modal .ant-modal-root .ant-picker-input .ant-picker-suffix svg path {
  fill: white;
}

.darkmode-modal .ant-modal-root .ant-input#basic_password:hover,
.darkmode-modal .ant-modal-root .ant-input#basic_password,
.darkmode-modal .ant-modal-root .ant-input#basic_confirm:hover,
.darkmode-modal .ant-modal-root .ant-input#basic_confirm {
  border: 0 !important;
}

.darkmode-modal .ant-modal-root .colemwidth .ant-form-item-control-input-content svg path {
  fill: white;
}

.darkmode-modal .ant-modal-content .ant-input-affix-wrapper {
  background: #273143;
  border-color: #384252;
}

.ant-form-item-label label {
  display: flex !important;
}

.profile-name {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
}

.profile-name-title {
  font-family: 'SourceSansPro-SemiBold';
}

.mobile-profile-top {
  align-items: center;
}

.profile-name-role {
  color: #384252;
  font-size: 18px;
}

/* meet css */

.date-module {
  width: 100% !important;
}

.date-input {
  margin-right: 0 !important;
}

.half-leave-col {
  padding-top: 5px !important;
  margin-bottom: 15px !important;
}

.tbl-attendance table {
  width: 100%;
  max-width: 800px;
  margin-top: 15px;
}

.tbl-attendance table td {
  width: 20%;
}

/* eventdashboard page */
.mx-0 {
  margin: 0;
}

.event-main {
  background-color: #f6f7f9;
  padding-top: 55px;
  min-height: 100vh;
}


.left-head-title {
  margin: 0;
  margin-bottom: 15px;
}

.holiday-slider {
  position: relative;
  transition: 0.5s;
  border-radius: 5px;
}

.holiday-slider:hover {
  box-shadow: inset 0px 0px 15px 2px #0000004d;
}

.main-card-slider {
  padding: 30px 25px 10px;
  backdrop-filter: blur(5px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  transition: 0.5s;
}

.main-card-slider:hover {
  box-shadow: inset 0px 0px 14px 3px #ffffff9e;
}

.holiday-detail {
  display: flex;
  align-items: center;
  padding: 17px 10px 0;
  justify-content: space-between;
  backdrop-filter: blur(0.5px);
}


.holiday-icon .ant-image {
  display: flex;
  align-items: flex-end;
}

.holiday-icon .holiday-img {
  width: 100%;
  height: 100%;
  position: relative;
}

.holiday-slider-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

}

.holiday-slider .slick-dots {
  margin: 0;
  bottom: 0 !important;
  padding: 10px 0;
}

.slider-left-text,
.slider-right-text {
  font-size: 15px;
  margin: 0;
  line-height: 20px;
  color: #fff;
}

.slider-right-text a,
.slider-right-text a:hover {
  color: #ffffff;
}

.holiday-detail h3 {
  font-size: 30px;
  line-height: 38px;
  margin: 0;
  color: #ffff;
}

.slider-text {
  min-height: 114px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 15px;
}

.holiday-detail p {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 10px 0;
  color: #fff;
}

.Leave-slider-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 0;
}


.Leave-viewall a,
.Leave-viewall a:hover {
  color: #000000;
}

.Leave-slider-heading h2 {
  color: black;
  font-family: 'SourceSansPro-SemiBold';
}

.Leave-slider {
  background: #fff;
  border: 1px solid #00000036;
  margin-top: 30px;
  padding: 14px;
  border-radius: 5px;
  transition: .5s;
}

.Leave-slider:hover,
.todayevent:hover {
  box-shadow: inset 0px 0px 15px 2px #0000004d;
}

.avtar .ant-avatar-square {
  border-radius: 50px !important;
  margin: 0 9px;
}

.emp-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 15px;
}

.todays-birthday .emp-detail {
  margin: 10px 13px;
}

.leave-detail {
  font-size: 11px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightside {
  padding-left: 25px;
  padding-top: 47px;
  width: 100%;
  max-width: 100%;
}

.mydash-right {
  width: 100% !important;
  max-width: 100% !important;
  padding: 21px 5px 0 20px;
}

.mydash-right table.table th td {
  border-width: 0px !important;
}

.announcement {
  background: white;
  padding: 15px 0;
  margin: 0 0 25px;
}

.sub-tab textarea {
  resize: none;
  border: none !important;
}

.main-tab .ant-tabs-nav-wrap {
  padding: 0 25px;
}

.main-tab .sub-tab {
  padding: 0 15px;
}

.dropdown-size {
  width: 20%;
}

.main-tab .ant-tabs-nav {
  margin: 0 0 5px !important;
}

.main-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  text-shadow: 0 0 0.25px currentcolor;
}

.main-tab .ant-tabs-tab:hover {
  color: #000;
}

.main-tab .ant-input:focus {
  box-shadow: none;
}

.todayevent {
  background: white;
  padding: 15px 0;
  border: 1px solid #c9c9c9;
  transition: .5s;
  border-radius: 5px;
}

.birthday-heading h3 {
  font-size: 15px;
  line-height: 25px;
  margin: 0;
  font-family: 'SourceSansPro-SemiBold';
}

.blank-birthday p {
  font-size: 15px;
}

.blank-birthday {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.birthday-heading {
  padding-top: 8px;
}

.ant-tooltip.ant-tooltip-placement-bottom .ant-tooltip-content {
  margin-top: -11px !important;
}

.leave-tool {
  text-align: center !important;
}

.pending-birthday {
  margin-top: 15px;
}

.anniversery-year,
.birth-date,
.join-date {
  line-height: 11px;
  font-size: 10px;
}

.emp-name {
  width: 90px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 17px;
  margin-top: 7px;
  margin-bottom: 0;
}

/* viewallpage */
.space {
  background-color: #f7f7f7;
  padding: 50px 15px;
}

.card-main {
  background: #ff5c37e0;
  padding: 14px;
  border: 1px solid #ffa500d1;
  border-radius: 5px;
  margin: 12px 10px;
}

.viewpage-heading {
  text-align: center;
  padding-top: 10px;
}

.viewpage-title {
  font-size: 30px;
  line-height: 39px;
  color: #000000;
}

.viewpage-detail {
  padding: 15px 15px 0 !important;
}

.both-btn {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.both-btn button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-color: #40a9ff;
  background: #40a9ff;
}

.both-btn button:hover,
.both-btn button:active,
.both-btn button:focus,
.both-btn button:visited {
  color: #fff;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
}

.both-btn button span {
  line-height: 10px;
}

.main-table .ant-table-thead>tr>th {
  background: #ededed !important;
}

.main-table .ant-table-thead>tr>th:nth-child(3) {
  width: 220px;
}

.table .ant-spin-nested-loading .ant-spin-container ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-table .table .ant-table {
  padding: 0;
}

.main-table .table .ant-table-container table>thead>tr:first-child th:first-child {
  width: 55px;
  text-align: center;
}

.main-table .table .ant-table-container table>thead>tr:last-child th:last-child {
  width: 135px;
  text-align: center;
}

.main-table .table .ant-spin-nested-loading .ant-table-tbody>tr>td:first-child {
  word-break: break-all;
  text-align: center;
}

.main-table .table .ant-spin-nested-loading .ant-table-tbody>tr>td:last-child {
  text-align: center;
  word-break: break-all;
}

.upload-img .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
  width: 441px !important;
  height: 175px !important;
}

.holiday-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.date-part .ant-form-item-label {
  margin-right: 9px;
}

.name-part .ant-form-item-label label::after {
  content: "*";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  color: red;
}

.date-part .ant-form-item-label label::after {
  content: "*";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  color: red;
}

.upload-img .ant-form-item-label label::after {
  content: "*";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  color: red;
}

.main-table .table .ant-space-item:last-child {
  height: 32px;
}

button.ant-btn.ant-btn-primary.dlt-btn {
  display: flex;
  align-items: center;
}

.max-w .ant-form-item-control {
  max-width: 100% !important;
}

.max-w .ant-row {
  margin-right: 0 !important;
}

.ant-row {
  margin-right: 0 !important;
}

.mobilemenu-logo {
  display: none;
}

.mobile-menu {
  background: transparent;
  border: none;
}

.mobile-menu svg {
  height: 20px;
  width: 20px;
  color: white;
}

.mobile-menu {
  display: none;
}

.status-blue,
.cardbackground .cardmargine .card-body .col-5 .dropdown .btn-danger-info {
  background-color: #00abff26 !important;
  color: #0069d4bd !important;
}

.status-green,
.cardbackground .cardmargine .card-body .col-7 .dropdown .btn-success-info {
  background-color: #00ff7c36 !important;
  color: #12892a !important;
}

.badge-light {
  background-color: #e9e9e9 !important;
  color: #404040 !important;
}

.tasklisttbl .col-sm-12 .table-borderless .mdb-dataTable-head tr th:nth-child(6) {
  text-align: center;
}

.tasklisttbl .col-sm-12 .table-borderless .mdb-dataTable-head tr th:nth-child(7) {
  text-align: center;
  width: 10%;
}

.tasktbl .table-borderless tr td:nth-child(1) {
  text-align: center !important;
}

.tasklisttbl .table-borderless tr td:nth-child(6) {
  text-align: center;
}

.tasklisttbl .table-borderless tr td:nth-child(6) i {
  margin: 0 !important;
}

.tasklisttbl .table-borderless tr td:nth-child(7) {
  text-align: center;
}

.mydash {
  display: flex;
  align-items: center;
}

.mydash-text {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  font-weight: 500;
  margin: 0;
  padding: 0 0 15px 15px;
}

.mydash-text:hover {
  color: #000000;
}

.quick-text-link {
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  margin: 0 0 20px 0;
  font-weight: 500;
  padding-bottom: 10px;
}

.quick-text-link:hover {
  color: #000000;
}

.quick-link {
  margin-bottom: 10px;
}

.checkin-btn {
  background: #ffffff40 !important;
  border: 1px solid #e9eaeb99 !important;
  cursor: pointer;
  border-radius: 5px !important;
  height: 25px !important;
  line-height: 10px !important;
  color: #fff !important;
}

.checkin-btn:hover span {
  color: #000 !important;
}

.check-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.check-text h3 {
  font-size: 20px;
  color: white;
  margin: 0 !important;
  padding: 5px 0;
}

.check-text p {
  font-size: 15px;
  color: white;
  margin: 0 !important;
  padding: 0;
}

.main-check-card {
  padding: 12px 15px;
  transition: 0.5s;
  background: linear-gradient(345deg, rgb(19 61 94) 15%, rgb(38 115 136) 100%);
  border-radius: 5px;
}

.main-check-card:hover {
  box-shadow: inset 0px 0px 10px 2px white;
  border-radius: 5px;
}

.main-check-card:hover .checkin-btn {
  background: white !important;
  color: #224b6a !important;
}

.checkin-time-text {
  color: white;
  font-size: 15px;
}

.down-table {
  margin-top: 15px;
}

.team-text p {
  font-size: 17px;
  margin: 0 0 3px 0;
}

.my-task-dropdown {
  width: 210px;
}

.main-team .team-table .ant-table-content .ant-table-tbody tr td img {
  width: 45px;
  height: 45px;
  border-radius: 50px;
}

.main-team .team-table .ant-spin-nested-loading .ant-table-content {
  width: 100%;
  overflow: auto;
  max-height: 300px !important;
}

.main-team .team-table .ant-table-thead {
  display: none;
}

.main-team .team-table .ant-table table .ant-table-tbody tr td {
  font-size: 14px;
}

.main-team .team-table .ant-table table .ant-table-tbody tr td:nth-child(1) {
  width: 13%;
}

.main-team .team-table .ant-table table .ant-table-tbody tr td:nth-child(3) {
  width: 27%;
}

.ant-table-cell a,
.mdb-dataTable a {
  color: #224b6a !important;
  font-family: 'SourceSansPro-SemiBold';
}

.ant-table-cell {
  color: #000 !important;
}

.ant-table-content table tbody tr:hover {
  background: #224b6a1a;
}

.team-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: #2b6797;
  color: #fff;
  padding-left: 15px;
  border-radius: 5px; */
}

.team-text .select-dropdown {
  width: 230px;
  margin-right: auto;
}

.team-task {
  background: #2986ce;
  color: white;
  padding: 1px 7px;
  border-radius: 5px;
  border: none;
}

.team-task:hover,
.team-task:active,
.team-task:focus {
  color: white;
}

.attendance-btn .export-btn {
  margin-right: 0 !important;
}

.export-btn {
  margin-right: 12px !important;
  display: flex !important;
  width: 40px;
  height: 32px !important;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
}

.export-btn span {
  height: 18px !important;
}

.export-btn span svg {
  font-size: 18px;
}

.right-emp-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end;
}

.task-table-rightside {
  display: flex !important;
}

.mydash-right .team-table tr th {
  white-space: nowrap;
}

.mydash-right .ant-table-content {
  overflow-x: scroll;
  min-height: auto;
}

.mydash-right table {
  overflow: auto;
  table-layout: auto;
  width: 100%;
  overflow-x: scroll;
  min-width: 0;
}

.mydash-right table>thead>tr th {
  padding: 8px 10px;
}

.mydash-right table>thead>tr th:nth-child(1) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(2) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(3) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(4) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(5) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(6) {
  width: 10%;
}

.mydash-right table>thead>tr th:nth-child(7) {
  width: 10%;
}

.mydash-right table>tbody>tr td {
  padding: 5px 10px;
  white-space: nowrap;
  border: none;
  border-bottom: 1px solid #cfcfcf8c;
}

.main-team ul {
  display: none;
}

.project-task-open {
  background-color: #e9e9e9 !important;
}

.task-common {
  display: flex;
  align-items: center;
}

/* uves css */
.task_table .ant-table tr th {
  padding: 10px !important;
  border: none;
}

.task_table .ant-table tr td {
  padding: 7px 5px !important;
  border: none;
}

.task_table .ant-table tr td:nth-child(1) {
  width: 30px;
  padding: 5px !important;
  text-align: center;
}

.task_table .ant-table tr td:nth-child(2) {
  width: 57px;
  padding: 5px !important;
  white-space: nowrap;
}

.task_table .ant-table tr td:nth-child(3) {
  width: 265px;
  padding: 5px !important;
  word-break: break-all;
}

.task_table .ant-table tr td:last-child {
  white-space: nowrap;
}

.task_table .ant-table-thead {
  display: none;
}

.left-col-description {
  width: 140px;
  flex: 0 0 140px;
  max-width: 140px;
  word-break: break-word;
}

.milestone-tbl {
  overflow: auto;
}

.milestone-tbl .mdb-dataTable-head tr th:nth-child(1) {
  word-break: break-all;
  width: 5%;
}

.milestone-tbl .mdb-dataTable-head tr th:nth-child(2) {
  white-space: nowrap;
  width: 15%;
}

.milestone-tbl .mdb-dataTable-head tr th:nth-child(3) {
  word-break: break-all;
  width: 72%;
}

.milestone-tbl .mdb-dataTable-head tr th:nth-child(4) {
  white-space: nowrap;
  width: 20%;
}


.sprint-tbl {
  overflow: auto;
}

.sprint-tbl .mdb-dataTable-head tr th:nth-child(1) {
  word-break: break-all;
  width: 5%;
}

.sprint-tbl .mdb-dataTable-head tr th:nth-child(2) {
  white-space: nowrap;
  width: 10%;
}

.sprint-tbl .mdb-dataTable-head tr th:nth-child(3) {
  word-break: break-all;
  width: 10%;
}

.sprint-tbl .mdb-dataTable-head tr th:nth-child(4) {
  word-break: break-all;
  width: 30%;
}

.sprint-tbl .mdb-dataTable-head tr th:nth-child(5) {
  white-space: nowrap;
  width: 10%;
}

/* uves css end */
.task-timer {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  background: #f0eded;
  border-radius: 6px;
  padding: 8px 12px;
}

.task-timer-second {
  margin-right: 15px;
}

.task-timer-btn-group button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 2px solid;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.task-timer-btn-start {
  color: #4caf50;
}

.task-timer-btn-stop {
  color: #f44336;
}

.activity-text {
  font-size: 17px;
  font-weight: 500;
}

.add-cmt {
  display: flex;
  align-items: flex-start;

}

.cmt-projectcss form {
  padding-left: 24px;
}

.tip-text P {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  margin-top: 8px;
}

.tip-text span {
  font-weight: 400;
}

.tip-text span b {
  font-weight: 500;
  padding: 2px 3px;
  background: gainsboro;
  border-radius: 5px;
  margin: 0 5px;
}

.user-name-detils {
  display: flex;
  color: #626262;
}

.user-name-detils p {
  margin: 7px 5px;
}

.user-name-detils p:first-child {
  margin-left: 0;
}

.user-name-detils p:nth-child(2) {
  margin-right: 0;
}

.history-name p {
  margin: 0;
}

.history-name p:nth-child(1) {
  color: #000;
}

.history-name p:nth-child(2) {
  margin: 0 5px 0 10px;
}

.status-data {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.status-data .danger-info {
  background-color: #00abff26 !important;
  color: #0069d4bd !important;
  padding: 0px 7px;
  border-radius: 5px;
}

.status-data .success-info {
  background-color: #00ff7c36 !important;
  color: #12892a !important;
  padding: 0px 7px;
  border-radius: 5px;
}

.status-txt {
  margin-bottom: 20px;
}

.user-cmt-detail {
  margin-bottom: 10px;
}

.user-cmt-detail p {
  margin: 0;
  word-wrap: break-word;
  white-space: break-spaces;
}

.label-history {
  margin: 0px 10px !important;
  padding: 0 5px;
  background: #c3c3c3c7;
  border-radius: 5px;
  display: none;
}

.label-comment {
  padding: 0px 5px;
  border-radius: 5px;
  background: #c3c3c3c7;
  display: none;
}

.all-task .label-history,
.all-task .label-comment {
  display: block;
  height: 17px;
  font-size: 11px;
}

.emp-task-tab .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 12px;
}

.emp-task-tab.ant-tabs-tab {
  padding: 10px 0;
}

.head-check {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff2e;
  border: 1px solid rgb(0 0 0 / 20%);
  color: #ffffff;
  border-radius: 8px;
  font-size: 11px;
  padding: 2px 5px;
}

.working-hour p:first-child {
  font-size: 12px;
  line-height: 17px;
}

.working-hour p:nth-child(2) {
  font-size: 15px;
  text-align: center;
  line-height: 18px;
  font-family: 'SourceSansPro-Bold';
}

.head-clock {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.space-check {
  padding: 0 12px;
}

.face-check,
.only-check,
.clock {
  border-right: 1px solid rgb(0 0 0 / 20%);
}

.head-face-in p,
.head-face-out p {
  margin: 0;
  font-size: 11px;
}

.head-face-in,
.head-face-out {
  display: flex;
  align-items: center;
}

.head-face div {
  font-family: 'SourceSansPro-SemiBold';
}

.left-task-view {
  overflow: auto;
  height: 100vh;
}

.view-task-bread ol li {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-task-bread ol li a {
  display: flex;
  align-items: center;
}

.add-issue-btn .anticon-plus {
  cursor: pointer;
}

.plus-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.issue-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.link-btn,
.cancle-btn {
  background: lightgrey;
  border: 1px solid #b1b1b1;
  padding: 2px 10px;
  line-height: 20px;
  margin-right: 10px;
  font-size: 15px;
}

.force-overflow p {
  font-family: sans-serif;
}

.head-slide {
  margin-top: 15px;
}

.total-leave-details {
  width: 60%;
  text-align: center;
}

.total-leave-details .ant-row .ant-col {
  width: 100%;
  max-width: 140px;
  margin: 10px;
  padding: 5px 0 9px 0;
  box-shadow: 0px 0px 8px 0px #0000002e;
  border-radius: 5px;
}

.total-leave-details .ant-statistic-content {
  line-height: 25px;
}

.total-leave-details .leave-counter-no .ant-statistic-title {
  font-family: 'SourceSansPro-SemiBold';
}

.due-table {
  background-color: #ffffff;
}

.qtr_leave {
  display: flex;
  align-items: center;
  padding: 5px 15px;
}

.qtr_leave p {
  font-size: 14px !important;
  padding: 0 !important;
  margin-right: 10px !important;
}


.qtr_leave .dropdown-toggle {
  padding: 7px 15px;
}

/* employee-report start */
.dashboard-main .emp-report {
  padding: 5px 0 10px;
}

.emp-report .emp-sub-tab {
  padding: 5px 25px 0;
}

.emp-report-table .ant-table-content .ant-table-thead tr th:nth-child(1) {
  width: 70%;
}

.emp-report-table .ant-table-content .ant-table-thead tr td:nth-child(1) {
  word-break: break-all;
}

.emp-report-table .ant-table-content .ant-table-thead tr th:nth-child(2) {
  width: 15%;
}

.emp-report-table .ant-table-content .ant-table-thead tr th:nth-child(3) {
  width: 15%;
}

.days-dropdown .ant-space-item {
  line-height: 0px;
}

.days-dropdown,
.days-dropdown:hover {
  color: #000000;
}

.progress-table .ant-table-container tr th {
  padding: 0 7px;
  border-bottom: 1px solid gray;
}

.progress-table .ant-pagination {
  display: none;
}

.progress-table .ant-table-container tr th:nth-child(1) {
  width: 20%;
}

.progress-table .ant-table-container tr th:nth-child(2) {
  width: 8%;
}

.progress-table .ant-table-container tr th:nth-child(3) {
  width: 70%;
}

.progress-table .ant-table-container tr td {
  padding: 7px 6px;
  border: 0;
}

.progress-table .ant-table {
  background: transparent;
}

.sevendays_table {
  margin-top: 15px;
}

.sevendays_table .ant-table .ant-table-content thead tr th:first-child {
  width: 5%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(2) {
  width: 17%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(3) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(4) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(5) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(6) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(7) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(8) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content thead tr th:nth-child(9) {
  width: 100%;
  max-width: 11%;
}

.sevendays_table .ant-table .ant-table-content tbody tr td a {
  color: #6174fb;
  text-decoration: underline;
}

/* employee-report end */

/* task-progress start */
.task-progress {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0 10px;
  margin: 20px 0 0 0;
  padding-bottom: 20px;
}

.task-progress .left-progress {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid gray;
  border-top: 4px solid #2684ff;
  border-radius: 5px;
  margin: 0 10px;
  min-height: 300px;
}

.progress-left-head {
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  padding: 15px 0;
}

.progress-table2 .ant-table-container tr th:nth-child(1) {
  width: 40%;
}

.progress-table2 .ant-table-container tr th:nth-child(2) {
  width: 12%;
}

.progress-table2 .ant-table-container tr th:nth-child(3) {
  width: 15%;
}

.progress-table2 .ant-table-container tr th:nth-child(4) {
  width: 12%;
}

.progress-table2 .ant-table-container tr th:nth-child(5) {
  width: 10%;
}

.progress-table2 .ant-table-container tr td {
  background: transparent;
  padding: 7px 7px;
}

.progress-table2 .ant-table-container .ant-table-tbody tr:last-child td {
  border-top: 1px solid gray;
}

.progress-table2 .ant-table-container tr th {
  padding: 7px 7px;
}

.progress-table2 .ant-pagination {
  display: none;
}

/* task-progress end */

/* my attendance start */
.event-main2 {
  padding-right: 0;
}

.attendance-head {
  justify-content: space-between;
}

.attendance-year {
  display: flex;
  align-items: center;
}

.attendance-year p {
  margin: 0 15px 0 0;
  font-size: 14px;
}


a.Refresh-btn,
a.Refresh-btn:hover {
  background: #3793d6;
  padding: 4px 12px;
  word-break: break-all;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
}

.box1 {
  border: 1px solid #8b8b8b82;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 5px;
  margin: 0 7px;
  min-height: 55px;
  min-width: 55px;
}

.upper {
  display: flex;
  align-items: center;
}

.upper span {
  height: 14px;
  width: 14px;
  border-radius: 50px;
  background-color: red;
}

.upper p {
  font-size: 12px;
  padding-left: 7px;
  word-break: break-all;
}

.atten-point {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}

.main-attendance-table {
  margin-top: 10px;
}

.main-attendance-table table .ant-table-thead tr th:nth-child(1) {
  width: 9%;
}

.main-attendance-table table .ant-table-thead tr th:nth-child(6) {
  width: 8%;
}

.main-attendance-table table .ant-table-thead tr th:nth-child(7) {
  width: 8%;
}

.main-attendance-table .ant-spin-container .ant-table .ant-table-thead tr th {
  background: #3676a596;
  color: white !important;
  padding: 10px 12px;
}

.main-attendance-table .ant-spin-container .ant-table .ant-table-tbody tr td {
  padding: 10px 15px;
}

/* my attendance end */

.team-leave-dropdown {
  justify-content: center !important;
}

.team-leave-dropdown button:first-child {
  display: none;
}

/* interview form */
.interview-tab {
  margin: 7px 10px;
  box-shadow: 0 0 10px #2986ce63;
  border-radius: 5px;
  border: 0.5px solid #2f4e8f52;
}

.apply-interview {
  display: flex;
  align-items: center;
}

.apply-interview .field-user {
  width: 177px !important;
}

.first-two-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apply-date .ant-form-item {
  margin-bottom: 0;
}

.apply-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appyle-date p {
  margin: 0 5px;
}

.apply-interview p {
  margin: 0 5px;
  font-size: 15px;
  white-space: nowrap;
}

.main-interview-form {
  padding: 15px 10px;
}

.main-personal-info .info-title {
  margin: 0 5px;
  font-size: 20px;
  font-weight: 600;
}

.main-personal-info {
  margin-top: 20px;
}

.intervierw-name {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 5px 0;
}

.intervierw-name .ant-form-item,
.intervierw-name .ant-col {
  width: 100%;
}

.intervierw-name p {
  width: 100%;
  max-width: 110px;
  font-size: 15px;
}

.Personal-information {
  margin-top: 8px;
}

.first-two-info .intervierw-name {
  width: 49%;
}

.intervierw-name .ant-form-item-row {
  margin: 0 7px;
}

.intervierw-name textarea {
  resize: none;
}

.intervierw-name .ant-col {
  display: flex;
}

.add-education {
  font-size: 20px;
  line-height: 0px;
}

.main-education-detail .ant-table .ant-table-tbody tr td:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
}

.main-education-detail .ant-table .ant-table-tbody tr td,
.main-reference .ant-table .ant-table-tbody tr td {
  padding: 12px 10px;
  border: none;
}

.main-work-ex .ant-table-thead tr:nth-child(2) th,
.main-work-ex .ant-table-tbody tr td:nth-child(2),
.main-work-ex .ant-table-tbody tr td:nth-child(3),
.main-work-ex .ant-table-tbody tr td:nth-child(4),
.main-work-ex .ant-table-tbody tr td:nth-child(5),
.main-work-ex .ant-table-tbody tr td:nth-child(6),
.main-work-ex .ant-table-tbody tr td:nth-child(7) {
  text-align: center;
}

.main-education-detail .ant-table-pagination-right {
  display: none;
}

.main-work-ex .ant-table-content {
  overflow: auto;
}

.main-work-ex table tbody tr td {
  padding: 0;
  border: none;
  padding: 0 5px;
}

.main-work-ex table thead tr:nth-child(1) th:nth-child(2) {
  width: 20%;
}

.main-work-ex table thead tr:nth-child(1) th:nth-child(3) {
  width: 20%;
}

.main-work-ex table thead tr:nth-child(1) th:nth-child(4) {
  width: 20%;
}

.main-work-ex table tbody tr td:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.main-reference .ant-table-pagination,
.main-work-ex .ant-table-pagination {
  display: none;
}

.main-attechment {
  display: flex;
  align-items: center;
}

.submit-interview button {
  width: 125px;
  box-shadow: 0px 0px 10px #0000007d;
  height: 35px;
}

/* interview form end */

/* screening start */

.hr-same-screen {
  margin: 5px 10px;
}

.table-screen table thead tr th:nth-child(1) {
  width: 30%;
}

.table-screen table thead tr th:nth-child(3) {
  width: 20%;
}

.table-screen table thead tr th:nth-child(4) {
  width: 20%;
}

.table-screen table thead tr th:last-child {
  width: 10%;
}

/* screening end */

/* diya css */
.teamsearch {
  display: flex;
  justify-content: space-between;
  width: 35%;
}

.tasklist-table .mdb-dataTable-head tr th:nth-child(3) {
  display: none
}

.tasklist-table tbody tr td:nth-child(3) {
  display: none
}

.searchname {
  width: 100%;
  margin-right: 10px;
}

.team-leave-view .ant-table-wrapper table thead tr th {
  background-color: #f3f4f7 !important;
  padding: 5px 15px !important;
  color: #000000 !important;
}

.team-leave-view .ant-table-wrapper table tbody tr td {
  padding: 10px !important;
  word-break: break-word;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(1),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(1) {
  width: 10%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(2),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(2) {
  width: 14%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(3),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(3) {
  width: 14%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(4),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(4) {
  width: 14%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(5),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(5) {
  width: 14%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(6),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(6) {
  width: 20%;
}

.team-leave-table .mdb-dataTable .mdb-dataTable-head tr th:nth-child(7),
.team-leave-table .ant-table .ant-table-thead tr th:nth-child(7) {
  width: 5%;
}

.team-leave-table .mdb-dataTable tbody tr td:last-child,
.team-leave-table .ant-table .ant-table-tbody tr td:last-child {
  text-align: center;
}

.team-leave-table .mdb-dataTable tbody tr td .ant-btn-group button:first-child,
.team-leave-table .ant-table .ant-table-tbody tr td .ant-btn-group button:first-child {
  display: none;
}

/* diya css end */


/* employee present list start */
.emp-present-table .ant-spin-container table thead tr th {
  text-align: center;
}

.emp-present-table .ant-spin-container table thead tr th:first-child {
  text-align: start;
}

.emp-present-table .ant-spin-container table tbody tr td {
  white-space: nowrap;
}

.list-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.list-head p {
  font-size: 25px;
  font-weight: 600;
  color: #444444b8;
  margin: 0;
  padding-left: 15px;
  padding-bottom: 7px;
}

.Toastify__toast-body {
  font-size: 14px;
}


/* leave model css */
.leave-notice-msg .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0;
}

.leave-notice-msg .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns {
  margin-top: 15px;
}

.leave-notice-msg .ant-modal-confirm-body-wrapper .ant-modal-confirm-btns button {
  width: 100px;
  height: 28px;
  line-height: 14px;
  border-radius: 7px;
  background: #0c4880bd;
  border-color: #0c488052;
}

.leave-notice-msg .ant-modal-confirm-body-wrapper .ant-modal-confirm-body .anticon {
  color: red;
  font-size: 30px;
}

.leave-notice-msg .ant-modal-confirm-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leave-notice-msg .ant-modal-confirm-content {
  margin-top: 12px;
  font-size: 15px;
}

.leave-notice-msg .ant-modal-confirm-content p {
  font-size: 15px;
}

/* chart page */
.sub-main-chart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-main-chart .left-side-chart {
  width: 50%;
  padding: 0 15px;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-head p {
  font-size: 20px;
  background: white;
  padding: 7px 13px;
  margin: 0 0px;
  box-shadow: 3px 1px 10px #00000052;
}


.ant-form-item-label {
  overflow: visible;
  white-space: nowrap !important;
}

.ant-col-10 {
  margin-left: 5%;
}

.margin-left-5 {
  margin-left: 5%;
}

.card {
  margin-left: 0 !important
}

.image {
  display: flex !important;
  flex-flow: row wrap;
}

.project-detail {
  padding: 0 !important;
}

.rolebg {
  border-radius: 7px;
  background-color: #ffa900;
}

.positionbg {
  border-radius: 7px;
  background-color: #b23cfd;
}

.cardbackground {
  background-color: rgb(250 250 250);
}

.project-detail .table>:not(caption)>*>* {
  padding: 0.5rem 1rem;
}

.rolebg {
  border-radius: 7px;
  background-color: #ffa900;
}

.btn-tresh:hover {
  border-color: red;
}

.project-detail .ant-modal-title {
  color: rgb(1, 63, 179);
  font-size: 19px;
}

.project-edit-model {
  margin-left: 5%;
}

.main-project-edit-modal .ant-col .ant-row {
  margin-right: 0 !important;
}

.ant-row {
  margin-right: 0 !important;
}



.ant-form-item-label {
  overflow: visible;
  white-space: nowrap !important;
}

.ant-col-10 {
  margin-left: 5%;
}

.image {
  display: flex !important;
  flex-flow: row wrap;
}


.pagination {
  justify-content: end;
}

.formbtn {
  float: right;
  margin-right: 44px;
  margin-bottom: -46px;
}

.ant-row {
  display: flex;
  margin-right: 0px;
}

.create-project-modal .ant-row {
  margin: 4px 0;
}

p {
  font-size: 13px;
}

.cardbackground {
  background-color: rgb(250 250 250);
}

.Commentmain {
  background-color: rgb(250 250 250);
  margin: 12px;
}

.Comments {
  margin-left: 20px;
  color: rgb(1, 63, 179);
  font-size: 20px;
}

.TaskKeyTitle h4 {
  margin: 0px 0px 25px -10px;
  color: rgb(1, 63, 179);
}

.TitleStyle {
  color: rgb(1, 63, 179);
  font-size: 17px;
  font-family: 'SourceSansPro-SemiBold';
}

.btn-tresh:hover {
  border-color: red;
}

.mt-20 {
  margin-top: 15px;
}


.bg-light {
  overflow-wrap: anywhere;
}


.g-height-50 {
  height: 50px;
}

.g-width-50 {
  width: 50px !important;
}

.g-height-25 {
  height: 30px;
}

.g-width-25 {
  width: 30px !important;
}


.g-bg-secondary {
  background-color: #fafafa !important;
}

.u-shadow-v18 {
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}

.g-color-gray-dark-v4 {
  color: #777 !important;
}

.g-font-size-12 {
  font-size: 0.85714rem !important;
}

.media-comment {
  margin-top: 20px;
}

.flot-right {
  float: right;
}

.DatePicker {
  padding: 5px 108px;
}

/* .cancelbtn {
  margin-right: -12px;
} */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 300px;
  height: 200px;
  margin-right: 8px;
  border-radius: 20px;
}

.uploadFile .ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #1266f1;
  color: #4d63ff;
  border-radius: 20px;
}

.uploadFile .ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
  color: black;
  pointer-events: none;
  border-radius: 20px;
}

/*  css for upload-list-item-thumbnail  */
a.ant-upload-list-item-thumbnail {
  pointer-events: none;
}

/* #endregion */

.attenchment.btn-light:hover {
  color: #ffffff;
  background-color: #0e52c1;
}

/* scroll bar style in ndescreption in view page start region*/

#style-7::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, rgb(122, 153, 217)),
      color-stop(0.72, rgb(73, 125, 189)),
      color-stop(0.86, rgb(28, 58, 148)));
}

/* #endregion */

/* #startregion for all table */
.tableFilterSection {
  background-color: #e7f7ff;
  margin: 8px 8px -8px 8px;
  padding: 10px;
  border-radius: 13px;
}

/* #endregion */

.HoverAddBugBtn:hover {
  background-color: #f93154;
  color: white;
}

.HoverBugListBtn:hover {
  background-color: #39c0ed;
  color: white;
}

.all-project-list .col-sm-12 table .mdb-dataTable-head tr {
  white-space: pre;
}

.tasklisttbl {
  overflow: auto;
}


/* loader */

.vh-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

svg .svg-elem-1 {
  stroke-dashoffset: 2144.410888671875px;
  stroke-dasharray: 2144.410888671875px;
  stroke-dashoffset: 0;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 3s ease-in 0s, fill 1s ease-in 2.5s;
  transition: stroke-dashoffset 3s ease-in 0s, fill 1s ease-in 2.5s;
  animation: vh-anim 6s infinite reverse;
}

@keyframes vh-anim {
  0% {
    stroke-dashoffset: 2144.410888671875px;
    fill: transparent;
  }

  40% {
    stroke-dashoffset: 0;
    fill: rgb(34, 112, 141);
  }

  50% {
    stroke-dashoffset: 0;
    fill: rgb(34, 112, 141);
  }

  60% {
    stroke-dashoffset: 0;
    fill: rgb(34, 112, 141);
  }

  100% {
    stroke-dashoffset: 2144.410888671875px;
    fill: transparent;
  }

}

/* end loader */

/* kra start */
.kra-modal-space {
  margin: 0;
  padding: 4px 0;
}

.tl-kra-modal .ant-modal-content .ant-modal-footer {
  display: none;
}

.tl-kra-modal .ant-modal-body {
  padding: 10px 15px !important;
}

/* .tl-kra-modal {
  top: 0 !important;
} */


.search-bar-kra {
  width: 37% !important;
}

.kra-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.kra-field {
  width: 50%;
  max-width: 50%;
  flex: 0 0 50%;
  margin: 0 5px;
}

/* kra end */

/* quarter leave css */
.my-quator-dropdown .ant-collapse-header .ant-collapse-expand-icon {
  line-height: 18px;
}

/* quarter leave css end */


/* login page css */
.fildewidth {
  width: 100%;
  height: 3rem;
}

.logo {
  text-align: center;
  margin: 0 0 15px 0;
}

img {
  width: 6rem;
}

.titaltext {
  text-align: center;
}


.logo img {
  width: 225px;
  height: 125px;
}

.titaltext {
  text-align: center;
}

.maindiv {
  background: url(../../assets/images/login-bg.png);
  /* background: linear-gradient(rgba(16, 99, 252, 0.65), rgba(62, 80, 97, 0.979)); */
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.main-form .ant-form-item-label {
  padding: 0 !important;
}

.main-form .ant-form-item-row {
  margin: 10px 0;
}

.titaltext .login-text {
  text-align: start;
  font-size: 30px !important;
  line-height: 45px;
  margin: 0 0 5px 0;
}

.main-form .login-input {
  margin-right: 0 !important;
}

.login-button {
  margin: 0 !important;
}

.hero-right {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.main-model {
  padding: 15px;
  box-shadow: 0px 0px 14px 6px #2299c18a;
  border-radius: 5px;
  width: 30vw;
}

.login-p {
  text-align: start;
  font-family: 'SourceSansPro-SemiBold';
  font-size: 15px;
  margin: 0;
  color: #000000a6;
}

.w-100 {
  width: 100% !important;
}

.login-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.login-input button {
  width: 170px;
  height: 35px;
}

.login-input button span {
  font-family: 'SourceSansPro-SemiBold';
  line-height: 25px;
}

.maindiv .ant-form .ant-row {
  margin-right: 0 !important;
}

.change-pass .card-body {
  padding: 0 !important;
}

.change-pass .card-body .card-title h5 {
  font-family: 'SourceSansPro-SemiBold';
}

.change-pass .card-body .ant-form-item {
  margin: 25px 0;
}

.change-pass .card-body .ant-form-item .ant-form-item-control-input-content {
  text-align: end;
}

.half-leave-section {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.leave-form .ant-modal-content .ant-modal-body {
  display: flex;
  justify-content: space-between;
}

.leave-form .ant-modal-content .ant-modal-body form {
  width: 100%;
  padding: 15px;
}

.leave-form .ant-modal-content .ant-modal-body {
  padding: 0;
}

.half-leave-section .ant-radio-group {
  width: 100%;
  max-width: 48%;
}

.half-leave-section .ant-radio-group label {
  width: 50%;
  text-align: center;
}

.leave-form .ant-modal-content .ant-modal-body .rightside_calender {
  padding: 0 20px;
  background-color: #1890ff4a;
  width: 40%;
}

.calender-heading {
  margin: 0 0 5px 0;
  font-size: 23px;
  word-break: break-all;
}

.password-input .ant-form-item-control-input .ant-input-password {
  padding: 0;
}

.password-input .ant-form-item-control-input .ant-input-password .ant-input {
  padding: 15px 30px 15px 13px;
}

.password-input .ant-form-item-control-input .ant-input-password .ant-input-suffix {
  margin: 0;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 9;
}

.rightside_calender .calnder .ant-picker-calendar-header .ant-radio-group {
  display: none;
}

.rightside_calender .calnder .ant-picker-calendar .ant-picker-panel .ant-picker-content th+td {
  border: none;
  padding-bottom: 8px;
}

.rightside_calender .calnder .ant-picker-calendar-mini .ant-picker-calendar-header {
  padding: 8px;
  /* border-bottom: 1px solid #1890ff54; */
}

.rightside_calender .calnder .ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0.5px 0 !important;
  user-select: none;
}

.rightside_calender .calnder .ant-picker-calendar-mini .ant-picker-content {
  height: 213px !important;
  /* width: 253px !important; */
}

.calender-detail {
  margin-bottom: 10px;
}

.leave-point {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.leave-circle {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}


.leave-circle .color-circle {
  height: 10px;
  width: 10px;
  background: #1890ff;
  border-radius: 50px;
  margin-right: 5px;
}

.leave-circle:nth-child(2) .color-circle {
  background: #ff0000ba;
}

.leave-circle:nth-child(3) .color-circle {
  background: #4b8d03;
}

.calnder .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #000;
  background: #00000040;
  user-select: none;
}

.calnder .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #000000;
}

.leave-name .ant-spin-nested-loading .ant-table-content {
  overflow: auto;
  max-height: 175px !important;
}

.leave-name .ant-table-content .ant-table-tbody tr td img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
}

.leave-name .ant-table table .ant-table-thead tr th {
  padding: 7px !important;
  font-size: 12px;
}

.leave-name .ant-table-pagination.ant-pagination {
  margin: 6px 0 !important;
}

.leave-name .ant-table-pagination.ant-pagination li {
  min-width: 25px !important;
  height: 25px !important;
}

.leave-name .ant-table-pagination.ant-pagination li button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 23px;
}

.right-leaveform .calnder .ant-picker-panel .ant-picker-content tbody tr td {
  border: none !important;
}

.leave-name .ant-table-pagination.ant-pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 23px;
}

.leave-name .ant-table table .ant-table-thead {
  position: sticky;
  top: 0px;
  z-index: 999;
}

.leave-name .ant-table-tbody tr td a {
  display: inherit;
}

.leave-name .ant-table table .ant-table-thead tr th:first-child {
  width: 5%;
}

.leave-name .ant-table table .ant-table-thead tr th:nth-child(2) {
  width: 138px;
}

.leave-name .ant-table table .ant-table-thead tr th:nth-child(3) {
  width: 80px;
}

.leave-name .ant-table table .ant-table-tbody tr td {
  padding: 4px !important;
  font-size: 11px;
  border: none;
  border-bottom: 1px solid #bfbfbf4a;
}

/* login page css end */

/* responsive start */
@media (min-width: 0) {
  .g-mr-15 {
    margin-right: 1.07143rem !important;
  }

  .g-mt-3 {
    margin-top: 0.21429rem !important;
  }

  .g-pa-30 {
    padding: 0.5rem !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .maindiv {
    background-size: cover;
  }

  .hero-left {
    display: none;
  }

  .hero-right {
    width: 100%;
    max-width: 100% !important;
  }

  .main-model {
    width: 700px;
    padding: 40px 20px;
    max-width: 700px;
    box-shadow: 5px 5px 14px 6px #2299c18a;
  }

  .top-header {
    padding: 0;
    margin-left: 2.4px;
  }

  .main-personal-info table thead tr th {
    font-size: 11px;
  }

  .intervierw-name p,
  .apply-interview p {
    font-size: 12px;
    max-width: 82px;
  }

  .main-personal-info .info-title {
    font-size: 16px;
  }

  .first-two-info .intervierw-name {
    width: 49%;
  }

  .intervierw-name textarea {
    resize: none;
    height: 100%;
    max-height: 43px !important;
    font-size: 12px;
  }

  .main-personal-info input {
    font-size: 12px;
  }

  .main-work-ex .ant-table-wrapper .ant-table table {
    width: 1200px !important;
  }

  .main-personal-info {
    margin-top: 9px;
  }

  .submit-interview {
    margin-top: 20px;
  }

  .edu-info .main-education-detail table thead tr th:nth-child(1),
  .edu-info .main-education-detail table thead tr th:nth-child(3),
  .edu-info .main-education-detail table thead tr th:nth-child(4),
  .edu-info .main-education-detail table thead tr th:nth-child(5) {
    width: 15%;
    white-space: nowrap;
  }

  .edu-info .main-education-detail table thead tr th:nth-child(2) {
    width: 50%;
  }
}

@media screen and (min-width:0px) and (max-width:599px) {


  .maindiv {
    background-size: auto;
  }

  .hero-left {
    display: none;
  }

  .hero-right {
    width: 100%;
    max-width: 100% !important;
  }

  .main-model {
    width: auto;
    padding: 20px 30px;
  }

  .login-input button {
    width: 140px;
    height: 35px;
  }

  .logo img {
    width: 185px;
    height: 105px;
  }

  .modelwidth {
    width: 100% !important;
  }

  .hero-right .main-login-form {
    width: 100%;
    max-width: 90%;
  }

  .main-form .ant-form-item:last-child {
    margin-bottom: 0;
  }

  .mydashboard-main {
    padding-right: 0px;
  }

  .mobile-menu {
    display: block;
  }

  .head-check {
    display: none;
  }

  .mobilemenu-logo {
    display: block;
    padding-top: 9px;
  }

  .main-drawer .ant-drawer-header {
    display: none;
  }

  .main-drawer .ant-drawer-body {
    padding: 0;
    background: #183953;
  }

  .main-drawer .ant-drawer-content-wrapper {
    width: 120px !important;
  }

  .main-drawer .ant-drawer-content-wrapper .drawer-list .ant-menu-submenu-title {
    width: 120px !important;
  }

  /* 
    /* .mydash-right .ant-table-content {
        overflow: auto;
    } */
  .drawer-list {
    margin-top: 45px;
  }

  .main-drawer .ant-drawer-content-wrapper .drawer-list .ant-menu-submenu-title {
    width: 130px !important;
  }

  .main-drawer.ant-drawer-content {
    background-color: black !important;
  }

  .mydash-right .ant-table-content {
    overflow: auto;
  }

  .team-text .select-dropdown {
    margin: 5px 0 10px 0;
  }

  .team-text p {
    padding: 0px;
  }

  .navbar-light .navbar-toggler {
    display: none !important;
  }

  .flexible-content .top-header {
    padding: 0 !important;
    left: 0;
    flex-direction: column;
    justify-content: center;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
  }

  .sidebar-fixed {
    display: none;
  }

  #content {
    margin-left: 0px !important;
  }

  .emp-name {
    width: 98px;
    white-space: inherit;
    text-align: center;
    overflow: hidden;
    text-overflow: inherit;
    line-height: 18px;
    min-height: 35px;
  }

  .event-main {
    padding-right: 0px !important;
  }

  .avtar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0px 0px;
  }

  .emp-detail {
    margin: 5px 23px;
  }

  .holiday-icon {
    margin-top: 0;
  }

  .holiday-icon .holiday-img {
    height: 105px !important;
  }

  .holiday-icon .ant-image {
    width: 155px !important;
    height: 100px !important;
  }

  .main-card-slider {
    padding: 25px 17px 6px;
  }

  .holiday-slider-heading {
    top: 1px;
  }

  .rightside {
    padding: 27px 0 0 0;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .todays-birthday .avtar {
    justify-content: space-between;
  }

  .main-tab .ant-tabs-nav-wrap {
    padding: 0 10px;
  }

  .sidebar-fixed .logo-wrapper {
    display: none;
  }

  .navbar-light .container-fluid {
    padding-left: 0;
    padding-right: 60px;
  }

  .mob-main {
    justify-content: end !important;
  }

  .logo-vh-set img {
    height: 35px;
    width: 45px;
  }

  .mob-main .nav-bar-user-name {
    margin-left: auto;
  }

  /* leave page */
  .list-head {
    flex-wrap: wrap;
  }

  .titel-set {
    margin-left: 0px;
  }

  .total-leave-details {
    width: 100%;
  }

  .list-head .ant-row {
    flex-flow: row;
    flex-wrap: wrap;
  }

  .total-leave-details .ant-row .ant-col {
    max-width: 135px;
    margin: 10px;
  }

  .qtr_leave {
    padding: 6px 0px;
  }

  .qtr_leave p {
    font-size: 13px !important;
  }

  .qtr_leave .ant-select-show-search {
    margin-right: 10px;
  }

  .mobile-scroll .mdb-datatable .col-sm-12:first-child>div {
    overflow: auto;
  }

  .mobile-scroll .mdb-datatable .col-sm-12:first-child>div>table {
    margin-bottom: 24px !important;
  }

  .demo {
    padding: 0 15px;
  }

  .table>:not(:last-child)>:last-child>* {
    white-space: nowrap;
  }

  .card>hr {
    display: none;
  }

  .ant-form-item {
    margin-bottom: 20px !important;
  }

  .half-leave-col .ant-radio-button-wrapper {
    padding: 0 43px;
  }

  .list-group-item {
    padding: 10px 15px;
  }

  .page-container,
  .create-project {
    justify-content: space-between;
  }

  .main-table .table .ant-table-content {
    overflow: auto
  }

  .upload-img .ant-upload-picture-card-wrapper .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 175px !important;
  }

  .ant-form-item-label label {
    margin-right: 9px !important;
  }

  .modal-main .upload-img .ant-form-item-row {
    flex-direction: column;
  }

  .master-dropdown,
  .master-dropdown:hover {
    color: #a2a9b4 !important;
    transition: .3s;
    text-align: start;
    padding: 6px 15px 0 15px;
  }

  .sidebar-dropdown {
    background-color: #273143 !important;
    box-shadow: none !important;
  }

  .sidebar-dropdown .ant-dropdown-menu-item {
    color: #a2a9b4 !important;
    transition: .3s;
    border-bottom: 1px solid #a2a9b438;
  }

  .sidebar-dropdown .ant-dropdown-menu-item:last-child {
    border-bottom: none;
  }

  .list-group-item-1 a li {
    text-align: start;
  }

  .report-mobile {
    width: 100% !important;
    border: none !important;
    background: none !important;
  }

  .report-mobile .ant-menu-submenu-active {
    color: white !important;
  }

  .report-mobile li {
    background: #273143 !important;
    color: #a2a9b4 !important;
  }

  .report-mobile .anticon {
    display: none;
  }

  .report-mobile li ul {
    background: transparent !important;
  }

  .report-mobile li ul li {
    padding-left: 20px !important;
    color: red !important;
  }

  .report-mobile li ul li a {
    color: #a2a9b4 !important;
  }

  .report-mobile .ant-menu-submenu-title {
    padding: 0 5px !important;
    margin: 0 !important;
    color: #a2a9b4 !important;
  }

  .report-mobile .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    top: 56% !important;
    right: 35px !important;
  }

  .half-leave-section .ant-radio-group {
    max-width: 100% !important;
    margin: 7px 0;
  }

  .half-leave-section {
    flex-direction: column;
  }

  /* profile responsive */
  .card-header {
    padding: 0;
  }

  .mobile-profile-top,
  .profile-head-mobile {
    padding: 8px 2px;
    align-items: center;
  }

  .profile-name-title,
  .profile-name-role {
    font-size: 13px;
    white-space: nowrap;
  }

  .mobile-emergency-name {
    white-space: nowrap;
  }

  .mobile-profile-row {
    flex-direction: column;
  }

  .mobile-w-100 {
    width: 100%;
  }

  .mobileprofile {
    width: 25% !important;
    margin-left: 10px !important;
  }

  .mobile-cardmargine {
    margin-bottom: 20px !important;
  }

  .mobile-tab {
    flex-wrap: inherit;
    overflow-x: auto;
  }

  .mobile-tab .tabfont {
    white-space: nowrap;
    margin-bottom: 0;
  }

  .mobile-tab .nav-link {
    padding: 15px 7px;
    border-bottom: none;
  }

  .profile-pic {
    width: 50%;
  }

  /* profile responsive end*/

  /* project responsive  */

  .page-menu-nav2 .nav-item a {
    white-space: nowrap;
  }

  .page-menu-nav {
    overflow: auto;
  }

  .project-body {
    margin: 0 !important;
  }

  .create-project .qa-btn-set .ml-10 {
    display: none;
  }

  .sub-main-chart {
    flex-direction: column;
  }

  .sub-main-chart .left-side-chart {
    width: 100%;
    min-height: 50vh;
    display: none;
  }

  .sub-main-chart .right-side-chart {
    display: block;
    padding: 0 !important;
  }

  .sub-main-chart .left-side-chart #chart,
  .sub-main-chart .left-side-chart #chart .apexcharts-canvas foreignObject {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub-main-chart .left-side-chart #chart .apexcharts-canvas,
  .sub-main-chart .left-side-chart #chart .apexcharts-canvas svg,
  .sub-main-chart .left-side-chart #chart .apexcharts-canvas foreignObject {
    width: 320px !important;
  }

  .task-progress .left-progress {
    margin: 0 !important;
    width: 500px;
  }

  .main-tab .sub-tab {
    padding: 0 !important;
  }

  .task-progress {
    overflow: auto;
  }

  .main-tab .ant-tabs-content-holder .ant-tabs-tabpane-active .bg-light-text-color .row {
    flex-direction: column;
  }

  .main-tab .ant-tabs-content-holder .ant-tabs-tabpane-active .bg-light-text-color .row .col-4 {
    display: flex;
    align-items: center;
    width: 100%;
    word-break: break-word;
    margin: 10px 0;
  }

  .main-tab .ant-tabs-content-holder .ant-tabs-tabpane-active .bg-light-text-color .row .col-4 p {
    margin: 0;
    line-height: 26px;
    margin-left: 10px;
  }

  /* project responsive end */
}

@media screen and (max-width: 370px) {
  .emp-detail {
    margin: 4px 17px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1230px;
  }
}

/* responsive end */