.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-picker {
  width: 100%;
}

img {
  width: 6rem;
}

.tasklisttbl {
  overflow: auto;
}

.tableFilterSection {
  background-color: #e7f7ff;
  margin: 8px 8px -8px 8px;
  padding: 10px;
  border-radius: 13px;
}

#content {
  margin-left: 117px;
}


li .menu-icon {
  display: block;
}

.logo-wrapper .img-fluid {
  margin: 15px 0;
}

.page-menu-nav {
  display: flex;
  columns: auto;
  flex-direction: row;
  background-color: #273143;
}

.page-menu-nav li.nav-item {
  padding: 0 10px;
}

.page-menu-nav li.nav-item a {
  color: white;
}